import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import "./Header.scss";

export default function Header() {
  const [fix, setFix] = useState(false);
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  function setFixed() {
    if (window.scrollY >= 50) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <Navbar
      className={
        fix
          ? "headerWrapperFixed headerWrapperMove"
          : "headerWrapper headerWrapperMove"
      }
      expand="lg"
    >
      {/* <div
        className={
          unixEndDate > new Date() && countdownSettings
            ? 'countdown'
            : 'countdownHidden'
        }
      >
        <CountdownTimer
          countdownTimer={countdownTimer}
          unixEndDate={countdownSettings}
        />
      </div> */}
      <Container className="header headerMove">
        <Navbar.Brand href="#">
          {fix ? (
            <Image
              className="header-brand"
              src={process.env.PUBLIC_URL + "/assets/airLogoWhite.svg"}
              height={"38px"}
              width={"94px"}
            />
          ) : (
            <Image
              className="header-brand"
              src={process.env.PUBLIC_URL + "/assets/Logo.svg"}
              height={"48px"}
              width={"114px"}
            />
          )}
        </Navbar.Brand>
        <div className="nav-items">
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <div
            className={
              fix
                ? "burger-menu-fixed burget-menu-move"
                : "burger-menu burget-menu-move"
            }
            onClick={updateMenu}
          >
            <div className={burger_class}></div>
            <div className={burger_class}></div>
            <div className={burger_class}></div>
          </div>

          <Nav className={menu_class}>
            <Nav.Link onClick={updateMenu} href="#team">
              Team
            </Nav.Link>

            <Nav.Link
              onClick={updateMenu}
              href="https://blog.3air.io/"
              target={"_blank"}
            >
              Blog
            </Nav.Link>
            <Nav.Link
              onClick={updateMenu}
              href="https://docs.3air.io/"
              target={"_blank"}
            >
              Docs
            </Nav.Link>
            <Nav.Link onClick={updateMenu} href="#token">
              Token
            </Nav.Link>
            <Nav.Link
              target={"_blank"}
              onClick={updateMenu}
              href="https://app.3air.io/"
            >
              App
            </Nav.Link>

            <Nav.Link
              onClick={updateMenu}
              target="_blank"
              href="https://app.3air.io/staking"
            >
              Staking
            </Nav.Link>
            <Nav.Link onClick={updateMenu} href="#subscribe">
              Contact
            </Nav.Link>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}
